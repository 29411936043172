
<script>
import { Chart, registerables } from 'chart.js';
import { HierarchicalScale } from 'chartjs-plugin-hierarchical';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(...registerables);
Chart.register(HierarchicalScale);
Chart.register(ChartDataLabels);
export default {
    name: '',
    type: '',
    props: {
        width:{
            type: String,
            required: true,
            default: '430',
        },
        bandera:{
            type: Boolean,
            required: false,
            default: false
        },
        height:{
            type: String,
            required: false,
            default: '100%',
        },
        title:{
            type: String,
            required: false,
            default: 'My First Dataset',
        },
        colors: {
            type: Array,
            required: false,
            default: () => [],
        },
        labels: {
            type: Array,
            required: true,
            default: () => [],
        },
        series: {
            type: Array,
            required: true,
            default: [],
        },
        positionLegend:{
            type: String,
            required: false,
            default: 'right',
        },
        legend: {
            type: Object,
            required: false,
            default: () => {
                return {
                    display: true,
                    position: 'right',
                }
            },
        },
        fontSize:{
            type: String,
            required : false,
            default: () => "11px"
        },
        textAlignLegend:{
            type: String,
            required: false,
            default: () => "start"
        },
        displayLegend:{
            type: Boolean,
            required: false,
            default: true,
        }
    },
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    data () {
        return {
            chart: undefined
        }
    },
    watch: {
        chartConfig () {
            this.updateChart()
        }
    },
    mounted () {
        this.renderChart()
    },
    beforeDestroy () {
        this.destroyChart()
    },
    computed: {
        safeId () {
            // as long as this._uid() works there is no need to generate the key
            const key = () => Math.random().toString(36).replace('0.', '')
            return '__safe_id__chart_pie' + (this._uid || key())
        },
        computedHeight() {
            return this.height;
        },
        computedtype () {
            return 'pie'
        },
        computedDatasets () {
            if(this.colors.length !== 0){
                return [{
                    label: this.title,
                    data: this.series,
                    backgroundColor: this.colors,
                    hoverOffset: 4
                }];
            }else{
                return [{
                    label: this.title,
                    data: this.series,
                    backgroundColor: [
                        'rgb(255, 99, 132)',
                        'rgb(54, 162, 235)',
                        'rgb(255, 205, 86)'
                    ],
                    hoverOffset: 4
                }];
            }
        },
        computedLabels () {
            if (this.labels && typeof this.labels !== 'string') {
                return this.labels
            } else if (!this.datasets || !this.datasets[0] || !this.datasets[0].data) {
                return []
            }
            const emptyLabels = Array(this.datasets[0].data.length).fill('')
            if (this.labels === 'indexes') {
                return emptyLabels.map((u, i) => i + 1)
            } else if (this.labels === 'months') {
                return emptyLabels.map((u, i) => this.$options.months[i % 12])
            }
            return emptyLabels
        },
        computedData () {
            return {
                datasets: this.computedDatasets,
                labels: this.computedLabels
            }
        },
        computedOptions () {
            return {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: this.displayLegend,
                        position: this.positionLegend,
                        align: this.textAlignLegend,
                        labels: {
                            generateLabels: function(chart) {
                                var data = chart.data;
                                if (data.labels.length && data.datasets.length) {
                                    return data.labels.map(function(label, i) {
                                        var value = data.datasets[0].data[i];
                                        var fill = data.datasets[0].backgroundColor[i];
                                        return {
                                            text: label + " : " + value,
                                            fillStyle: fill,
                                            index: i
                                        }
                                    })
                                } else {
                                    return [];
                                }
                            },
                            font: {
                                size: this.fontSize
                            }
                        }
                    },
                    datalabels: {
                        color: '#fff',
                        display: true,
                        formatter: function(value, ctx) {
                            if(value === 0 || value === "0" || value === ""){
                                return ""
                            }else if(value === "0.5" || value === 0.5){
                                return "0%";
                            }else{
                                let sum = 0;
                                let dataArr = ctx.chart.data.datasets[0].data;
                                dataArr.map(data => {
                                    sum += data;
                                });
                                let percentage = (value*100 / sum).toFixed(2)+"%";
                                return percentage;
                            }
                        }
                    },
                }
            }
        },
        chartConfig () {
            return {
                type: this.computedtype,
                data: this.computedData,
                options: this.computedOptions,
            }
        }
    },
    methods: {
        renderChart () {
            this.destroyChart()
            this.chart = new Chart(
                this.$refs.canvas.getContext('2d'),
                this.chartConfig
            )
        },
        updateChart () {
            if (this.chart) {
                Object.assign(this.chart, this.chartConfig)
                this.chart.update()
            }
        },
        destroyChart () {
            if (this.chart) {
                this.chart.destroy()
            }
        }
    },
    render(h) {
        return h(
            'div',
            [
                h(
                    'canvas', {
                        attrs: {
                            id: this.safeId
                        },
                        style: {
                            height: this.height
                        },
                        ref: 'canvas',
                    }
                )
            ]
        )
    }
}
</script>